.componentPage {
  display: flex;
  align-items: center;

  .pageItem {
    width: 35px;
    height: 35px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 100%;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease 0s;
  }

  .pageItem:hover {
    color: white;
    background-color: #002fa7;
  }

  .normal {
    color: #69707d;
    background-color: white;
  }

  .selected {
    color: white;
    background-color: #002fa7;
  }
}