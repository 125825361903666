.headerOption {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding-top: 30px;
  padding-right: 20px;
}

.main {
  height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px; /** logo 占用了高度，看起来不居中，所以要整体向上移动 */
  padding-left: 25px;
  padding-right: 25px;

  .logo {
    width: 160px;
    height: 160px;
  }

  .input {
    width: 100%;
    margin-top: 30px;
  }
}