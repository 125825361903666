.resultListItem {
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 30px;
  background-color: white;
  transition: all 0.5s ease 0s;

  a {
    line-height: 1.28;
    cursor: pointer;
    color: #002fa7;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    color: #69707d;
  }

  a:hover {
    text-decoration: none;
  }

  .url {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #b3b8c1;
  }

  .title {
    margin-top: 10px;
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .content {
    margin-top: 10px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-lines: 2;
    color: #69707d;
  }
}

.resultListItem:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.06);
}