.header {
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  // 让header悬浮
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: saturate(180%) blur(5px);

  .headerMain {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 70px;
      height: 70px;
    }

    .input {
      width: 100%;
      height: 40px;
    }
  }

  .sub {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .sourceItem {
      text-align: center;
      font-size: 12px;
      background-color: #002fa7;
      border-radius: 50px;
      color: white;
      padding: 3px 10px;
      margin-right: 30px;
    }
  }
}

.main {
  padding-left: 10px;
  padding-right: 10px;
  //margin-top: 30px;
  // header悬浮,main要设置margin
  margin-top: 170px;

  .wrapTop {
    width: 100%;

    .aiAnswerContainer {
      width: 100%;
      height: min-content;
      padding: 15px;
      border-radius: 20px;
      margin-bottom: 30px;
      background-color: white;

      .title {
        font-size: 16px;
        color: #002fa7;
      }

      .feedback {
        color: #b3b8c1;
        font-size: 12px;
        cursor: pointer;
      }

      .content {
        margin-top: 10px;
        font-size: 14px;
        color: #69707d;
        white-space: break-spaces;
        word-break: break-all;
      }
    }
  }

  .wrapBottom {
    width: 100%;

    .resultList {
      width: 100%;
    }
  }
}

.footer {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;

  .page {
  }
}

.loading {
  //margin-top: 100px;
  // header悬浮,loading要设置margin
  margin-top: 290px;
  display: flex;
  justify-content: center;
}

.error {
  // header悬浮,loading要设置margin
  margin-top: 290px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: indianred;
}