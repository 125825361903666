* {
    margin: 0;
    padding: 0;
}

body {
    font-family: Roboto, arial, sans-serif !important;
}

#root, .root-stable-view {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Mobile */
@media (max-width: 700px) {
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}