@import url(https://fonts.googleapis.com/css?family=Roboto:400,900italic,900,700italic,700,500italic,500,400italic,300italic,300,100italic,100);

.componentLoading {
  text-align: center;
  position: relative;
  width: 100px;

  p {
    font-size: 40px;
    font-weight: 100;
    color: #002fa7;
  }

  h1 {
    color: #002fa7;
    font-size: 60px;
    margin-top: -10px;

    .abs {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  hr {
    background: #002fa7;
    border: none;
    height: 1px;
  }

  .color {
    width: 0;
    overflow: hidden;
    color: #002fa7;
  }
}