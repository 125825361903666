.componentHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .button {
    border-radius: 50px;
    background-color: #002fa7;
    color: white;
    font-size: 12px;
    text-align: center;
    padding: 8px 15px;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.5s ease 0s;
  }

  .button:hover {
    box-shadow: 0 8px 15px rgba(0, 47, 167, 0.3);
  }

  .buttonMobile {
    color: #69707d;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
  }
}