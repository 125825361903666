.header {
  background-color: white;
  padding: 10px 0;
  min-width: 1200px;
  // 让header悬浮
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: saturate(180%) blur(5px);

  .headerMain {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headerMainSearch {
      display: flex;
      align-items: center;

      .logo {
        width: 90px;
        height: 90px;
        margin-left: 40px;
        cursor: pointer;
      }

      .input {
        width: 650px;
        margin-left: 30px;
      }
    }

    .headerMainOption {
      display: flex;
      align-items: center;
      margin-right: 45px;
    }


  }

  .sub {
    display: flex;
    align-items: center;
    margin-left: 170px;

    .sourceItem {
      text-align: center;
      font-size: 12px;
      background-color: #002fa7;
      border-radius: 50px;
      color: white;
      padding: 3px 10px;
      margin-right: 30px;
    }
  }
}

.main {
  display: flex;
  //margin-top: 70px;
  // header悬浮,main要设置margin
  margin-top: 170px;

  .wrapLeft {
    width: 810px;
    display: flex;
    justify-content: flex-end;

    .resultList {
      width: 650px;
      margin-left: 60px;
    }
  }

  .wrapRight {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    .aiAnswerContainer {
      width: 350px;
      height: min-content;
      padding: 15px;
      border-radius: 20px;
      margin-bottom: 30px;
      background-color: white;

      .title {
        font-size: 16px;
        color: #002fa7;
      }

      .feedback {
        color: #b3b8c1;
        font-size: 12px;
        cursor: pointer;
      }

      .content {
        margin-top: 10px;
        font-size: 14px;
        color: #69707d;
        white-space: break-spaces;
        word-break: break-all;
      }
    }
  }
}

.footer {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 60px;

  .page {
    margin-left: 165px;
  }
}

.loading {
  //margin-top: 150px;
  // header悬浮,loading要设置margin
  margin-top: 300px;
  display: flex;
  justify-content: center;
}

.error {
  // header悬浮,loading要设置margin
  margin-top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: indianred;
}

.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 47, 167, 0.25);
  border-radius: 15px;
  font-size: x-small;
  color: #69707d;
  padding: 10px 20px;
  z-index: 9999;
  cursor: pointer;
  animation: notification .2s linear;
}

@keyframes notification {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}