.componentCard {
  .title {
    font-size: 16px;
    color: #002fa7;
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .wrap {
    padding: 15px;
    border-radius: 20px;
    margin-bottom: 60px;
    background-color: white;

    .tag {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      overflow: hidden;

      .tagItem {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .tagIndicator {
          width: 10px;
          height: 10px;
          background-color: #002fa7;
          border-radius: 100%;
        }

        .tagContent {
          color: #002fa7;
          font-size: 14px;
          margin-left: 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .content {
      font-size: 14px;
      color: #69707d;
      white-space: break-spaces;
      word-break: break-all;
    }
  }
}