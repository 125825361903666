.container {
  width: 750px;
  align-self: center;
}

.header {
  margin: 90px 0;

  .title {
    color: #002fa7;
    font-weight: bold;
    font-size: xx-large;
  }
}

.main {
  p {
    color: #69707d;
  }

  ul {
    list-style: circle inside;
    margin-top: 45px;

    li {
      color: #69707d;
      font-weight: bold;
      margin-bottom: 45px;

      p {
        font-size: small;
        font-weight: normal;
        margin-left: 20px;
        margin-top: 5px;
      }
    }
  }
}