.container {
  width: 750px;
  align-self: center;
}

.header {
  margin: 90px 0;

  .title {
    color: #002fa7;
    font-weight: bold;
    font-size: xx-large;
  }
}

.main {
  p {
    color: #69707d;
  }

  .submitContainer {
    margin-top: 45px;

    .submitDesc {
      color: #69707d;
      font-size: small;

      strong {
        font-weight: bold;
      }
    }

    .submitBox {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .inputBox {
        flex-grow: 1;
        height: 40px;
        border-radius: 50px;
        border: #002fa7 solid 1px;
        outline: 0;
        background-color: transparent;
        color: #69707d;
        font-size: 14px;
        padding: 0 20px;
      }

      .inputBox::placeholder {
        color: #b3b8c1;
      }

      .submitBtn {
        border-radius: 50px;
        background-color: #002fa7;
        color: white;
        font-size: 12px;
        text-align: center;
        padding: 10px 30px;
        margin-left: 30px;
        cursor: pointer;
        transition: all 0.5s ease 0s;
      }

      .submitBtn:hover {
        box-shadow: 0 8px 15px rgba(0, 47, 167, 0.3);
      }
    }
  }
}