.componentInput {
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: #002fa7 solid 1px;

  .searchIcon {
    width: 20px;
    height: 20px;
  }

  .inputBox {
    height: 100%;
    border: none;
    outline: 0;
    background-color: transparent;
    flex-grow: 1;
    margin-left: 15px;
    color: #69707d;
    font-size: 16px;
  }

  .inputBox::placeholder {
    color: #b3b8c1;
  }
}